import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import Cache from 'ion-cache'

export const FETCH_MAGAZINECOVER = 'api/FETCH_MAGAZINECOVER'
export const FETCH_MAGAZINECOVER_SUCCESS = 'api/FETCH_MAGAZINECOVER_SUCCESS'
export const FETCH_MAGAZINECOVER_ERROR = 'api/FETCH_MAGAZINECOVER_ERROR'

const SERVER_URL = typeof window !== 'undefined' ? '' : 'http://localhost:' + process.env.PORT
const cache = new Cache()

export async function fetchMagazineCoverAPI () {
  const result = await cache.get('default')
  if (!result) {
    return axios.get(SERVER_URL + '/data/magazine-cover')
      .then(response => cache.set('default', response.data, 60 * 60000)) // Cache for 60 minutes
      .catch(err => {
        // console.error(err)
        throw err
      })
  }
  return result
}

function * fetchMagazineCoverSaga () {
  try {
    const res = yield call(fetchMagazineCoverAPI)
    yield put({ type: FETCH_MAGAZINECOVER_SUCCESS, payload: res })
  } catch (e) {
    yield put({ type: FETCH_MAGAZINECOVER_ERROR, payload: e.message })
  }
}

export function * watchFetchMagazineCover () {
  yield takeLatest(FETCH_MAGAZINECOVER, fetchMagazineCoverSaga)
}

// Saga actions
export const fetchMagazineCover = () => ({ type: FETCH_MAGAZINECOVER, isFetching: true, hasFetched: false })

export const initialState = {
  lastFetch: 0,
  didInvalidate: false,
  isFetching: false,
  hasFetched: false,
  hasError: false,
  canonical: null,
  error: null,
  magazineCover: null // do not mutate these
}

export const Reducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case FETCH_MAGAZINECOVER:
      return Object.assign({}, state, {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        hasError: false,
        canonical: null,
        error: null
      })
    case FETCH_MAGAZINECOVER_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        error: payload,
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        canonical: null
      })
    case FETCH_MAGAZINECOVER_SUCCESS:
      return Object.assign({}, state, {
        lastFetch: new Date(),
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        magazineCover: payload,
        hasError: false,
        canonical: null,
        error: null
      })
    default:
      return state
  }
}
