"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactHelmet = _interopRequireDefault(require("react-helmet"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var SectionMeta = function SectionMeta(props) {
  var schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: process.env.RAZZLE_SITE_TITLE,
    alternateName: process.env.RAZZLE_TITLE_KEY,
    url: process.env.RAZZLE_SITE_URL + props.location.pathname,
    description: props.description
  };
  return _react["default"].createElement(_reactHelmet["default"], null, _react["default"].createElement("script", {
    type: "application/ld+json"
  }, "".concat(JSON.stringify(schema))));
};

var _default = SectionMeta;
exports["default"] = _default;