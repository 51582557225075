"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactHelmet = _interopRequireDefault(require("react-helmet"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ArticleMeta = function ArticleMeta(_ref) {
  var article = _ref.article,
      canonical = _ref.canonical;
  var imageList;
  var images = [];

  if (article.images) {
    article.images.map(function (image) {
      return images.push({
        '@type': 'ImageObject',
        description: image.caption,
        url: image.url,
        width: image.width,
        height: image.height
      });
    });
  }

  if (article.inArticleSchema && article.inArticleSchema.images) {
    article.inArticleSchema.images.map(function (image) {
      return images.push({
        '@type': 'ImageObject',
        description: image.description,
        url: image.url,
        width: image.width,
        height: image.height
      });
    });
  }

  if (images.length > 1) {
    imageList = [];
    imageList.push({
      '@list': images
    });
  } else {
    imageList = images;
  }

  var schema = {
    '@context': 'http://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': process.env.RAZZLE_SITE_URL + (canonical || '/' + article.getCanonicalUri())
    },
    headline: article.headline,
    description: article["abstract"],
    image: imageList,
    datePublished: article.published,
    dateModified: article.modified || article.published,
    author: {
      '@type': 'Person',
      name: article.author
    },
    publisher: {
      '@type': 'Organization',
      name: process.env.RAZZLE_SITE_TITLE,
      logo: {
        '@type': 'ImageObject',
        url: process.env.RAZZLE_SITE_URL + '/logo.jpg',
        width: '60',
        height: '60'
      }
    }
  };

  if (article.videos) {
    var video = article.videos[0];

    if (video && video.url && video.script && /\.mp4$/.test(video.url)) {
      schema.video = {
        '@type': 'VideoObject',
        name: process.env.RAZZLE_SITE_TITLE,
        description: video.caption || '',
        thumbnailUrl: video.thumbnailURL || '',
        uploadDate: article.published,
        duration: video.duration || '',
        contentUrl: video.url
      };
    }
  }

  return _react["default"].createElement(_reactHelmet["default"], null, _react["default"].createElement("script", {
    type: "application/ld+json"
  }, "".concat(JSON.stringify(schema))));
};

var _default = ArticleMeta;
exports["default"] = _default;