import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'

export const DESIGN_DIRECTORY = 'api/DESIGN_DIRECTORY'
export const DESIGN_DIRECTORY_SUCCESS = 'api/STORY_SUCCESS'
export const DESIGN_DIRECTORY_ERROR = 'api/STORY_ERROR'

const SERVER_URL = typeof window !== 'undefined' ? '' : 'http://localhost:' + process.env.PORT

const feedbackAPI = (data) => {
  console.log('feedbackAPI' + SERVER_URL + '/data/hag-design-directory')
  return axios.post(SERVER_URL + '/data/hag-design-directory', data)
    .then(response => response.data)
    .catch(err => {
      // console.error(err)
      throw err
    })
}

function * designDirectorySaga ({ data }) {
  try {
    yield call(feedbackAPI, data)
    yield put({ type: DESIGN_DIRECTORY_SUCCESS })
  } catch (e) {
    console.error('ERROR while submitting', e.message)
    yield put({ type: DESIGN_DIRECTORY_ERROR, payload: { message: e.message } })
  }
}

export function * watchDesignDirectory () {
  yield takeLatest(DESIGN_DIRECTORY, designDirectorySaga)
}

// Saga actions
export const submitDesignDirectory = (data) => ({ type: DESIGN_DIRECTORY, data })

export const initialState = {}

export const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DESIGN_DIRECTORY:
      return Object.assign({}, state, {
        didInvalidate: false,
        isSubmitting: true,
        hasSubmitted: false,
        hasError: false,
        error: null,
        data: payload
      })
    case DESIGN_DIRECTORY_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        error: payload.message,
        hasSubmitted: true,
        isSubmitting: false,
        didInvalidate: true
      })
    case DESIGN_DIRECTORY_SUCCESS:
      return Object.assign({}, state, {
        hasSubmitted: true,
        isSubmitting: false,
        didInvalidate: false
      })
    default:
      return state
  }
}
