import { PAGE_VIEW } from './app'
import { FETCH_ARTICLE_SUCCESS, SERVER_ARTICLE_RENDER } from './article'

// Map the event to a Redux action
export const eventsMap = {
  [PAGE_VIEW]: (action) => ({
    event: 'VirtualPageview',
    virtualPageURL: action.payload
  }),
  [SERVER_ARTICLE_RENDER]: (action) => ({
    event: 'ArticlePageview',
    contentKey: action.payload.contentKey,
    section: action.payload.section,
    author: action.payload.author,
    host: process.env.RAZZLE_SITE_URL
  }),
  [FETCH_ARTICLE_SUCCESS]: (action) => ({
    event: 'ArticlePageview',
    contentKey: action.payload.contentKey,
    author: action.payload.author,
    section: action.payload.section,
    visitorId: action.payload.visitorId,
    host: process.env.RAZZLE_SITE_URL
  })
}
