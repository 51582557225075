import React from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'ion-navchevron'
import logo from '../../static/general/african-independent.svg'
import logoM from '../../static/general/african-independent-mobile.svg'
import { Articles } from 'ion-article-cmp'
import NoImage from '../../static/no-image.png'
import { ImageOrNoImage } from 'ion-image'
import { Button } from 'semantic-ui-react'
import { Facebook, Twitter, Instagram, LinkedIn, MenuIco, Rss } from 'ion-icon'
import { defaultWidth, DesktopTablet, Mobile } from 'ion-media'
import { connect } from 'react-redux'

const MenuTab = (props) => (
  <Articles section={props.section} pageSize={4} component={({ articles, noImage }) => {
    return articles.map((article) => (
      <article key={article.contentKey}>
        <Link to={'/' + article.getCanonicalUri()}>
          <ImageOrNoImage shape='square' width={320} image={article.image} alt={article.headline} noImage={noImage} />
          <h3>{article.headline}</h3>
        </Link>
      </article>
    ))
  }} noImage={NoImage} notFound={() => (<div>Not Found</div>)} {...props} />
)

const Header = (props) => (
  <header role='banner'>
    <a href='#main-content' className='show-on-focus' tabIndex='0'>
      Skip to content
    </a>
    <div className='wrapper'>
      <Mobile values={{ deviceWidth: defaultWidth }}>
        <Button className='menu-btn' onClick={props.toggleMenu}><MenuIco width='24' height='24' /></Button>
      </Mobile>
      <Link to='/' className='logo'>
        <Mobile values={{ deviceWidth: defaultWidth }}>
          <img src={logoM} alt='African Indy mobile' />
        </Mobile>
        <DesktopTablet values={{ deviceWidth: defaultWidth }}>
          <img src={logo} alt='African Indy desktop' />
        </DesktopTablet>
        <span>Rewriting Africa’s Narrative</span>
      </Link>
      <DesktopTablet values={{ deviceWidth: defaultWidth }}>
        <div className='follow-us'>
          <span>Follow us</span>
          <nav>
            <ul>
              <li>
                <a href={process.env.RAZZLE_SITE_FACEBOOK} target='_blank' rel='noopener noreferrer' title={`Like ${process.env.RAZZLE_SITE_NAME} on Facebook`}>
                  <Facebook width='18' height='18' />
                </a>
              </li>
              <li>
                <a href={process.env.RAZZLE_SITE_INSTAGRAM} target='_blank' rel='noopener noreferrer' title={`Follow ${process.env.RAZZLE_SITE_NAME} on Instagram`}>
                  <Instagram width='18' height='18' />
                </a>
              </li>
              <li>
                <a href={process.env.RAZZLE_SITE_TWITTER} target='_blank' rel='noopener noreferrer' title={`Follow ${process.env.RAZZLE_SITE_NAME} on Twitter`}>
                  <Twitter width='18' height='18' />
                </a>
              </li>
              <li>
                <a href={process.env.RAZZLE_SITE_LINKEDIN} target='_blank' rel='noopener noreferrer' title={`Follow ${process.env.RAZZLE_SITE_NAME} on LinkedIn`}>
                  <LinkedIn width='18' height='18' />
                </a>
              </li>
              <li className='social-follow'>
                <a href={process.env.RAZZLE_SITE_RSS} target='_blank' rel='noopener noreferrer' title={`Subscribe to the ${process.env.RAZZLE_SITE_NAME} RSS Feed`}>
                  <Rss width='18' height='18' />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </DesktopTablet>
    </div>
    <DesktopTablet values={{ deviceWidth: defaultWidth }}>
      <div className='wrapper'>
        <nav>
          <ul>
            <li>
              <NavLink to='/business'>Business</NavLink>
              <ul className='menu-articles'>
                <li>
                  <Link to='/Business'>Business</Link>
                  <MenuTab section='business' {...props} />
                </li>
              </ul>
            </li>
            <li>
              <NavLink to='/technology'>Tech</NavLink>
              <ul className='menu-articles'>
                <li>
                  <Link to='/technology'>Tech</Link>
                  <MenuTab section='technology' {...props} />
                </li>
              </ul>
            </li>
            <li>
              <NavLink to='/opinion'>Leading Voice</NavLink>
              <ul className='menu-articles'>
                <li>
                  <Link to='/opinion'>Leading Voice</Link>
                  <MenuTab section='opinion' {...props} />
                </li>
              </ul>
            </li>
            <li>
              <NavLink to='/culture'>Lifestyle & Culture</NavLink>
              <ul className='menu-articles'>
                <li>
                  <Link to='/culture'>Lifestyle & Culture</Link>
                  <MenuTab section='culture' {...props} />
                </li>
              </ul>
            </li>
            <li>
              <NavLink to='/environment'>Environment</NavLink>
              <ul className='menu-articles'>
                <li>
                  <Link to='/environment'>Environment</Link>
                  <MenuTab section='environment' {...props} />
                </li>
              </ul>
            </li>
            <li>
              <NavLink to='/sport'>Sport</NavLink>
              <ul className='menu-articles'>
                <li>
                  <Link to='/sport'>Sport</Link>
                  <MenuTab section='sport' {...props} />
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </DesktopTablet>
  </header>
)

export default connect((state) => ({ articles: state.articles }))(Header)
