"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ArticleImage = function ArticleImage(_ref) {
  var image = _ref.image,
      href = _ref.href,
      altText = _ref.altText,
      imageCaption = _ref.imageCaption,
      imageCredit = _ref.imageCredit,
      imageClass = _ref.imageClass;
  return _react["default"].createElement("figure", {
    itemProp: "image",
    itemScope: true,
    itemType: "https://schema.org/ImageObject"
  }, _react["default"].createElement("meta", {
    itemProp: "url",
    content: image.url
  }), _react["default"].createElement("meta", {
    itemProp: "width",
    content: image.width
  }), _react["default"].createElement("meta", {
    itemProp: "height",
    content: image.height
  }), _react["default"].createElement("img", {
    className: imageClass,
    src: href,
    alt: altText || imageCaption,
    title: imageCaption
  }), imageCaption && _react["default"].createElement("figcaption", null, _react["default"].createElement("span", {
    className: "imageCaption"
  }, imageCaption), imageCredit && _react["default"].createElement("span", {
    className: "imageCredit"
  }, "Credit: ", imageCredit)));
};

var _default = ArticleImage;
exports["default"] = _default;