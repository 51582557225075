import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../static/general/african-independent-mobile.svg'
import { Mobile } from 'ion-media'
import Ad from 'ion-ads'
import LazyLoad from 'react-lazyload'

const Footer = (props) => (
  <Fragment>
    <footer role='contentinfo'>
      <div className='wrapper'>
        <nav>
          <ul>
            <li><Link to='/about-us'>About African Independent</Link></li>
            <li><Link to='/contact-us'>Contact us</Link></li>
            <li><Link to='/feedback'>Feedback</Link></li>
            <li><Link to='/complaints'>Complaints Procedure</Link></li>
            <li><Link to='/terms-and-conditions'>Terms and Conditions</Link></li>
          </ul>
        </nav>
        <LazyLoad>
          <img src={logo} alt={process.env.RAZZLE_SITE_TITLE} />
        </LazyLoad>
        <p>&copy; {new Date().toLocaleDateString('en-ZA', { year: 'numeric' })} ANA Publishing/Independent Media and affiliated companies. All rights reserved.</p>
        <p>Please visit the official Government information portal for Coronavirus by clicking <a href='https://sacoronavirus.co.za/' target='_blank' rel='noopener noreferrer nofollow'>HERE</a></p>
      </div>
    </footer>
    <Mobile>
      <Ad path={`/${process.env.RAZZLE_DFP_CODE}/${props.section || 'homepage'}`} targeting={{ 'mobile-leaderboard': 'sticky' }} className='ad-leaderboard-sticky' mobile={[[320, 50]]} />
    </Mobile>
  </Fragment>
)

export default Footer
