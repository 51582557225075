import axios from 'axios'
import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects'
// import { fetchArticleContent } from '../store/article'
import Cache from 'ion-cache'

export const FETCH_ARTICLES = 'api/FETCH_ARTICLES'
export const FETCH_ARTICLES_SUCCESS = 'api/FETCH_ARTICLES_SUCCESS'
export const FETCH_ARTICLES_ERROR = 'api/FETCH_ARTICLES_ERROR'

export const FETCH_MORE_ARTICLES = 'api/FETCH_MORE_ARTICLES'
export const FETCH_MORE_ARTICLES_SUCCESS = 'api/FETCH_MORE_ARTICLES_SUCCESS'
export const FETCH_MORE_ARTICLES_ERROR = 'api/FETCH_MORE_ARTICLES_ERROR'

const SERVER_URL = typeof window !== 'undefined' ? '/data/content' : process.env.RAZZLE_CONTENT
const cache = new Cache()

export async function fetchArticlesAPI (section, offset, limit) {
  if (offset === 0) {
    const result = await cache.get(section)
    if (result && result.contents && result.contents.length >= limit) {
      // console.log('fetchArticlesAPI', 'HIT', section, limit)
      result.contents = result.contents.slice(0, limit)
      return result
    }
    limit = Math.max(24, limit)
  }
  return axios.get(SERVER_URL + '/v2/section/' + process.env.RAZZLE_TITLE_KEY + '/' + offset + '/' + limit + '/' + section)
    .then(response => {
      if (offset === 0) {
        return cache.set(section, response.data)
      } else {
        return Promise.resolve(response.data)
      }
    })
    .then(response => {
      return response
    })
    .catch(err => {
      // console.error(err)
      throw err
    })
}

function * fetchArticlesSaga ({ section, offset, limit }) {
  try {
    // const start = new Date()
    const res = yield call(fetchArticlesAPI, section, offset, limit + 1)
    // console.log('Fetched', section, offset, limit, 'articles in', new Date() - start, 'ms')
    let payload = {}
    if (res.contents) {
      payload = {
        lastFetch: new Date(),
        hasMore: res.contents.length > limit,
        articles: res.contents.slice(0, limit),
        authorName: res['author-name'] ? res['author-name'] : null,
        sectionLabel: res.sectionLabel
      }
    }
    yield put({ type: FETCH_ARTICLES_SUCCESS, payload, section })
  } catch (e) {
    console.error('ERROR while fetching section', section, 'json:', e.message)
    yield put({ type: FETCH_ARTICLES_ERROR, payload: { section, message: e.message } })
  }
}

function * fetchMoreArticlesSaga ({ section, offset, limit }) {
  try {
    let data = yield select(getArticles, section)
    if (data.articles.length < offset + limit) {
      const res = yield call(fetchArticlesAPI, section, data.articles.length, limit + 1)
      data = {
        section,
        hasMore: res.contents.length > limit,
        articles: data.articles.concat(res.contents.slice(0, limit))
      }
    } else {
      data = {
        section,
        hasMore: true,
        articles: data.articles
      }
    }
    yield put({ type: FETCH_MORE_ARTICLES_SUCCESS, payload: data })
  } catch (e) {
    console.error('ERROR while fetching section', section, 'json:', e.message)
    yield put({ type: FETCH_MORE_ARTICLES_ERROR, payload: { section, message: e.message } })
  }
}

// function * prefetchArticles ({payload}) {
//   if (payload.articles) {
//     for (let i = 0; i < payload.articles.length; i++) {
//       yield put(fetchArticleContent(payload.articles[i].contentKey))
//     }
//   }
// }

export function * watchFetchArticles () {
  yield takeEvery(FETCH_ARTICLES, fetchArticlesSaga)
  yield takeLatest(FETCH_MORE_ARTICLES, fetchMoreArticlesSaga)
  // if (typeof window !== 'undefined') {
  //   yield takeEvery(FETCH_ARTICLES_SUCCESS, prefetchArticles)
  // }
}

// Saga actions
export const getArticles = (state, section) => state.articles[section]
export const getAllArticles = (state, section) => state.articles
export const fetchArticles = (section, offset, limit) => ({ type: FETCH_ARTICLES, section, offset, limit })
export const fetchMoreArticles = (section, offset, limit) => ({ type: FETCH_MORE_ARTICLES, section, offset, limit })

export const Reducer = (state = {}, { type, payload, meta, section, message }) => {
  const newPayload = {}

  switch (type) {
    case FETCH_MORE_ARTICLES:
    case FETCH_ARTICLES:
      newPayload[section] = Object.assign({}, state[section], {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        hasError: false,
        error: null
      })
      return Object.assign({}, state, newPayload)
    case FETCH_ARTICLES_ERROR:
    case FETCH_MORE_ARTICLES_ERROR:
      console.log('Proccessing ERROR', type, payload, meta, section, message)
      newPayload[section] = {
        hasError: true,
        error: message,
        hasFetched: true,
        isFetching: false,
        didInvalidate: true
      }
      return Object.assign({}, state, newPayload)
    case FETCH_MORE_ARTICLES_SUCCESS:
      newPayload[payload.section] = {
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        articles: payload.articles,
        hasMore: payload.hasMore
      }
      return Object.assign({}, state, newPayload)
    case FETCH_ARTICLES_SUCCESS:
      newPayload[section] = {
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        articles: payload.articles,
        hasMore: payload.hasMore,
        lastFetch: payload.lastFetch,
        authorName: payload.authorName,
        sectionLabel: payload.sectionLabel
      }
      return Object.assign({}, state, newPayload)
    default:
      return state
  }
}
