import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Helmet from 'react-helmet'
import { withRouter, Redirect } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import loadable from '@loadable/component'
import { Sidebar, Segment, Menu } from 'semantic-ui-react'
import ScrollToTop from 'ion-scrolltotop'
import { Mobile } from 'ion-media'
import { url2section } from 'ion-sections'
import { DFPSlotsProvider } from 'react-dfp'

import Header from './Shell/Header'
import Footer from './Shell/Footer'
import NavMenu from './Shell/NavMenu'

import FaviconApple from '../static/favicon/apple-touch-icon.png'
import Favicon32 from '../static/favicon/favicon-32x32.png'
import Favicon16 from '../static/favicon/favicon-16x16.png'
import FaviconSafari from '../static/favicon/safari-pinned-tab.svg'
import FaviconICO from '../static/favicon/favicon.ico'
import logo from '../static/general/african-independent.svg'

import { hideMenu, toggleMenu, toggleSearch, pageView, onSlotRenderEndedLeft, onSlotRenderEndedRight } from '../../store/app'
import { fetchArticles, fetchMoreArticles } from '../../store/articles'
import '../static/general/normalize.min.css'

import 'semantic-ui-css/components/sidebar.min.css'
import 'semantic-ui-css/components/segment.min.css'
import 'semantic-ui-css/components/menu.min.css'
// import 'semantic-ui-css/semantic.min.css'
import '../styles/app.scss'

export const HomePage = loadable(() => import('../pages/LandingPages/HomePage'))
export const NotFound = loadable(() => import('../pages/NotFound'))
export const DefaultSection = loadable(() => import('./DefaultSection'))
export const Article = loadable(() => import('../pages/Article'))
export const StaticPage = loadable(() => import('../pages/StaticPage'))
export const SearchPage = loadable(() => import('../pages/SearchPage'))
export const Feedback = loadable(() => import('../pages/Feedback'))
export const SubscribePrint = loadable(() => import('../pages/SubscribePrint'))

// GPT.enableSingleRequest()

export class App extends Component {
  constructor (props) {
    super(props)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.hideMenu = this.hideMenu.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
    this.state = {
      wingbannerLeftActive: false,
      wingbannerRightActive: false
    }
    // this.props.pageView(this.props.location.pathname)
  }

  componentDidUpdate (prevProps) {
    // Re-fetch articles when the contentKey changes
    if (prevProps.location.pathname !== this.props.location.pathname) {
      setTimeout(() => {
        this.props.pageView(this.props.location.pathname)
      }, 0)
    }
  }

  toggleSearch () {
    this.props.toggleSearch()
  }

  toggleMenu () {
    this.props.toggleMenu()
  }

  hideMenu () {
    if (this.props.showMenu) {
      this.props.hideMenu()
    }
  }

  render () {
    return <DFPSlotsProvider dfpNetworkId=''>
      <div className='app'>
        <Helmet
          defaultTitle={process.env.RAZZLE_SITE_TITLE}>
          <html lang='en' />
          <link rel='icon' type='image/x-icon' href={FaviconICO} />
          <link rel='apple-touch-icon' sizes='180x180' href={FaviconApple} />
          <link rel='icon' type='image/png' sizes='32x32' href={Favicon32} />
          <link rel='icon' type='image/png' sizes='16x16' href={Favicon16} />
          <link rel='mask-icon' href={FaviconSafari} color='#5bbad5' />
          <meta name='msapplication-TileColor' content='#ffffff' />
          <meta name='theme-color' content='#ffffff' />
          <meta name='description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
          <meta name='msvalidate.01' content='5A0910AA1A12E32A5352933CF5FD96E9' />
          <meta property='fb:app_id' content='1915095138801045' />
          <meta property='og:type' content='article' />
          <meta charset='utf-8' />
          <meta property='og:title' content={process.env.RAZZLE_SITE_TITLE} />
          <meta property='og:description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
          <meta property='og:image' content={logo} />
          <meta itemprop='inLanguage' content='en' />
          <meta itemprop='image' content={logo} />
          <meta name='viewport' content='width=device-width, initial-scale=1, viewport-fit=cover' />
          <meta name='mobile-web-app-capable' content='yes' />
          <meta name='theme-color' content='#ffffff' />
          <meta name='google-site-verification' content='fyTv9tZUKg2TbyQpo-VO-Kz3GMovn5JQhvYo6cw9fdE' />
          <meta itemprop='image' content={logo} />
          <meta itemprop='thumbnailUrl' content={logo} />
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:image:src' content={logo} />
          <body className={(this.props.showMenu ? 'menu-open' : 'menu-closed') + (this.props.wingbannerLeftActive || this.props.wingbannerRightActive ? ' dfp-take-over-ad' : '')} />
        </Helmet>
        <Sidebar.Pushable as={Segment}>
          <Sidebar as={Menu} animation='push' width='wide' visible={this.props.showMenu} icon='labeled' vertical>
            <Mobile>
              <NavMenu toggleMenu={this.toggleMenu} />
            </Mobile>
          </Sidebar>
          <Sidebar.Pusher dimmed={this.props.showMenu} onClick={this.hideMenu}>
            <Header toggleMenu={this.toggleMenu} toggleSearch={this.toggleSearch} {...this.props} />
            <main id='main-content' role='main' className='content' >
              <ScrollToTop>
                <Switch>
                  <Route exact path='/' render={p => <HomePage {...this.props} />} />
                  <Route exact path='/shell' render={() => <div>Loading...</div>} />
                  <Route exact path='/feedback' component={Feedback} />
                  <Route exact path='/feedback/thankyou' render={() => <Feedback thanksMessage={'Thank you for your feedback. We will get back to you shortly.'} />} />
                  <Route exact path='/subscribe/print' component={SubscribePrint} />
                  <Route exact path='/subscribe/thankyou' render={() => <Feedback thanksMessage={'Thank you for your subscription request. We will get back to you shortly.'} />} />
                  <Route exact path='/:page(about-us)' render={props =>
                    <StaticPage
                      page={props.match.params.page}
                      title='About Us | African Independent'
                      description='African Independent is a business, technology and lifestyle publication. We aim to rewrite Africa’s narrative by focusing solely on the positive success stories coming from Africa.'
                      {...this.props}
                    />} />
                  <Route exact path='/:page(subscriptions)' render={props =>
                    <StaticPage
                      page={props.match.params.page}
                      title='About Us | African Independent'
                      description='African Independent is a business, technology and lifestyle publication. We aim to rewrite Africa’s narrative by focusing solely on the positive success stories coming from Africa.'
                      {...this.props}
                    />} />
                  <Route exact path='/:page(contact-us)' render={props =>
                    <StaticPage
                      page={props.match.params.page}
                      title='Contact Us for More Information | African Independent'
                      description='We would love to hear from you! Click here to contact us and we will respond as soon as possible.'
                      {...this.props}
                    />} />
                  <Route exact path='/:page(complaints)' render={props =>
                    <StaticPage
                      page={props.match.params.page}
                      title='Complaints Procedure | African Independent'
                      description='The Independent Media Complaints Procedure.'
                      {...this.props}
                    />} />
                  <Route exact path='/:page(terms-and-conditions)' render={props =>
                    <StaticPage
                      page={props.match.params.page}
                      title='Terms and Conditions | African Independent'
                      description='By using the African Independent website, users are subjected to the Terms and Conditions set out on this page.'
                      {...this.props}
                    />} />
                  <Route exact path='/:page(magazine)' render={props =>
                    <StaticPage
                      page={props.match.params.page}
                      title='Sign Up to our Newsletter for your Source of Inspiration | African Independent'
                      description='Features, news and reviews from the African Independent magazine archive.'
                      {...this.props}
                    />} />
                  <Route exact path='/search' render={props => <SearchPage {...this.props} />} />

                  {/* Defaults and direct article access */}
                  <Route path='/preview' render={props => <Article {...this.props} {...props} url='preview' contentKey={999999} />} />
                  <Route path='/:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})' render={props => <Article {...this.props} {...props} contentKey={props.match.params.contentKey} />} />
                  <Route path='/:contentKey([0-9]{5,})' render={props => <Article {...this.props} {...props} contentKey={props.match.params.contentKey} />} />
                  <Route path='/:section([a-zA-Z0-9-]+)/' strict exact render={props => <Redirect to={'/' + props.match.params.section} />} />
                  <Route path='/:section([a-zA-Z-]+)' render={props => <DefaultSection {...this.props} {...props} twitterName={process.env.RAZZLE_SITE_TWITTER_USER} />} />
                  {/* <Route exact path='/404' component={NotFound} {...this.props} /> */}
                  <Route path='*' component={NotFound} {...this.props} />
                </Switch>
              </ScrollToTop>
            </main>
            <Footer showSubscribe={!(this.props.location.pathname === '/subscribe/newsletters')} section={url2section(this.props.location.pathname)} />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    </DFPSlotsProvider>
  }
}

const mapStateToProps = (state) => (Object.assign(state.app, state.articles))
const mapDispatchToProps = (dispatch) => bindActionCreators({ hideMenu, toggleMenu, toggleSearch, pageView, fetchArticles, fetchMoreArticles, onSlotRenderEndedLeft, onSlotRenderEndedRight }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
