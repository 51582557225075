import { all } from 'redux-saga/effects'
import { watchFetchArticles } from './articles'
import { watchFetchMostReadArticles } from './mostReadArticles'
import { watchFetchArticleContent, watchFetchArticle, watchPreviewArticle } from './article'
import { watchNavChanges, watchPrecache } from './app'
import { watchFetchStaticPage } from './staticPage'
import { watchFetchMagazineCover } from './magazineCover'
import { watchFeedback } from './feedback'
import { watchSearchArticles, watchSearchArticlesMore } from './search'
import { watchSubscribe } from './subscribe'
import { watchSubscribePrint } from './subscribePrint'
import { watchDesignDirectory } from './designDirectory'

export default function * rootSaga () {
  yield all([
    watchFetchArticleContent(),
    watchFetchArticle(),
    watchPreviewArticle(),
    watchFetchArticles(),
    watchFetchMostReadArticles(),
    watchNavChanges(),
    watchFeedback(),
    watchDesignDirectory(),
    watchPrecache(),
    watchFetchStaticPage(),
    watchSubscribe(),
    watchSubscribePrint(),
    watchSearchArticles(),
    watchSearchArticlesMore(),
    watchFetchMagazineCover()
  ])
}
