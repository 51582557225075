import React from 'react'
import { NavLink } from 'ion-navchevron'
import 'semantic-ui-css/components/menu.min.css'
import { Facebook, Twitter, Instagram, Close, Rss } from 'ion-icon'

const NavMenu = ({ toggleMenu }) => (
  // eslint-disable-next-line
  <nav role='navigation'>
    <button className='close-btn' onClick={toggleMenu}><Close width='20' height='20' /></button>
    <ul className='menu-social'>
      <li>
        <a href={process.env.RAZZLE_SITE_FACEBOOK} target='_blank' rel='noopener noreferrer' title={`Like ${process.env.RAZZLE_SITE_NAME} on Facebook`}>
          <Facebook width='30' height='30' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_INSTAGRAM} target='_blank' rel='noopener noreferrer' title={`Follow ${process.env.RAZZLE_SITE_NAME} on Instagram`}>
          <Instagram width='30' height='30' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_TWITTER} target='_blank' rel='noopener noreferrer' title={`Follow ${process.env.RAZZLE_SITE_NAME} on Twitter`}>
          <Twitter width='30' height='30' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_RSS} target='_blank' rel='noopener noreferrer' title={`Subscribe to the ${process.env.RAZZLE_SITE_NAME} RSS Feed`}>
          <Rss width='30' height='30' />
        </a>
      </li>    </ul>
    <div className='mobile-nav-search'>
      <form action='/search' method='get'>
        <input type='search' placeholder={`Search ${process.env.RAZZLE_SITE_NAME}`} name='q' id='q' />
        <button><i className='fas fa-search' /></button>
      </form>
    </div>
    <ul className='main-nav'>
      <li>
        <NavLink to='/business'>Business</NavLink>
      </li>
      <li>
        <NavLink to='/technology'>Tech</NavLink>
      </li>
      <li>
        <NavLink to='/opinion'>Leading Voice</NavLink>
      </li>
      <li>
        <NavLink to='/culture'>Lifestyle & Culture</NavLink>
      </li>
      <li>
        <NavLink to='/environment'>Environment</NavLink>
      </li>
      <li>
        <NavLink to='/sport'>Sport</NavLink>
      </li>
    </ul>
  </nav>)

export default NavMenu
