"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactHelmet = _interopRequireDefault(require("react-helmet"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var HomePageMeta = function HomePageMeta(props) {
  var schema = {
    '@context': 'https://schema.org',
    '@graph': [{
      '@type': 'WebPage',
      name: process.env.RAZZLE_SITE_TITLE,
      alternateName: process.env.RAZZLE_TITLE_KEY,
      url: process.env.RAZZLE_SITE_URL,
      description: process.env.RAZZLE_SITE_DESCRIPTION
    }, {
      '@type': 'NewsMediaOrganization',
      name: process.env.RAZZLE_SITE_TITLE,
      alternateName: process.env.RAZZLE_TITLE_KEY,
      url: process.env.RAZZLE_SITE_URL,
      logo: process.env.RAZZLE_SITE_URL + '/logo.jpg',
      sameAs: [process.env.RAZZLE_SITE_FACEBOOK, process.env.RAZZLE_SITE_TWITTER, process.env.RAZZLE_SITE_INSTAGRAM]
    }]
  };
  return _react["default"].createElement(_reactHelmet["default"], null, _react["default"].createElement("script", {
    type: "application/ld+json"
  }, "".concat(JSON.stringify(schema))));
};

var _default = HomePageMeta;
exports["default"] = _default;