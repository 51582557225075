import axios from 'axios'
import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects'
import IonArticle from 'ion-article'
import { fetchArticlesAPI } from './articles'
import { USER_SET_VISITORID } from './user'
import Cache from 'ion-cache'

export const FETCH_ARTICLE_CONTENT = 'api/FETCH_ARTICLE_CONTENT'
export const FETCH_ARTICLE_CONTENT_SUCCESS = 'api/FETCH_ARTICLE_CONTENT_SUCCESS'
export const FETCH_ARTICLE_CONTENT_ERROR = 'api/FETCH_ARTICLE_CONTENT_ERROR'
export const FETCH_ARTICLE = 'api/FETCH_ARTICLE'
export const FETCH_ARTICLE_SUCCESS = 'api/FETCH_ARTICLE_SUCCESS'
export const FETCH_ARTICLE_VERIFY_URL = 'api/FETCH_ARTICLE_VERIFY_URL'
export const FETCH_ARTICLE_ERROR = 'api/FETCH_ARTICLE_ERROR'
export const FETCH_ARTICLE_NOT_FOUND = 'api/FETCH_ARTICLE_NOT_FOUND'
export const SET_PREVIEW_ARTICLE = 'api/SET_PREVIEW_ARTICLE'
export const SUBMIT_COMPETITION = 'api/SUBMIT_COMPETITION'
export const SUBMIT_COMPETITION_SUCCESS = 'api/SUBMIT_COMPETITION_SUCCESS'
export const SUBMIT_COMPETITION_ERROR = 'api/SUBMIT_COMPETITION_ERROR'
export const SERVER_ARTICLE_RENDER = 'api/SERVER_ARTICLE_RENDER'

const SERVER_URL = typeof window !== 'undefined' ? '/data/content' : process.env.RAZZLE_CONTENT
const CANONICAL_FETCHER_URL = typeof window !== 'undefined' ? '/data/canonical/' : process.env.RAZZLE_CANONICAL_FETCHER_URL

const cache = new Cache()

export async function fetchArticleAPI (contentKey) {
  const key = '/article/' + contentKey
  const result = await cache.get(key)
  if (result) {
    return result
  }
  return axios.get(SERVER_URL + key)
    .then(response => {
      // console.log('fetchArticleAPI:', SERVER_URL + key)
      return cache.set(key, response.data, 60000)
    })
    .catch(error => {
      console.log('fetchArticleAPI:', SERVER_URL + key, error.message)
      throw error.message
    })
}

const COMPETITION_SERVER_URL = typeof window !== 'undefined' ? '' : 'http://localhost:' + process.env.PORT

async function fetchCompetition (competitionSlug) {
  const key = '/competition/' + competitionSlug
  const result = await cache.get(key)
  if (result) {
    return result
  }
  return axios.get(COMPETITION_SERVER_URL + '/data/competition/' + competitionSlug)
    .then(response => {
      return cache.set(key, response.data)
    })
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

const submitCompetitionAPI = (formData) => {
  return axios.post(COMPETITION_SERVER_URL + '/data/competition',
    formData
  )
    .then(data => data.data)
    .catch(error => console.error(error))
}

async function fetchCanonicalURL (contentKey) {
  return axios.get(CANONICAL_FETCHER_URL + '/' + contentKey)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

async function fetchIonArticle (content) {
  return axios({
    method: 'post',
    headers: { 'Content-Type': 'text/plain' },
    url: SERVER_URL + '/preview',
    data: content
  })
    .then(data => data.data)
    .catch(error => console.error(error))
}

function * fetchArticleContentSaga ({ contentKey }) {
  try {
    const res = yield call(fetchArticleAPI, contentKey)
    yield put({ type: FETCH_ARTICLE_CONTENT_SUCCESS, payload: res })
  } catch (e) {
    yield put({ type: FETCH_ARTICLE_CONTENT_ERROR, payload: e.message })
  }
}

export function * watchFetchArticleContent () {
  yield takeLatest(FETCH_ARTICLE_CONTENT, fetchArticleContentSaga)
}

function fetchVisitorId () {
  let result = false
  if (typeof window !== 'undefined' && window.localStorage) {
    result = window.localStorage.getItem('visitorId')
  }
  if (!result) {
    result = Math.random().toString(36).substr(2, 9)
    if (typeof window !== 'undefined' && window.localStorage) {
      window.localStorage.setItem('visitorId', result)
    }
  }
  return result
}

function * fetchArticleSaga ({ contentKey }) {
  try {
    // const start = new Date()
    const res = yield call(fetchArticleAPI, contentKey)
    res.relatedArticles = res.relatedArticles ? res.relatedArticles : []
    const article = new IonArticle(res)
    article.relatedArticles = res.relatedArticles ? res.relatedArticles : []
    let canonicalUri = '/' + article.getCanonicalUri()
    const competitionSlug = article.getCompetitionSlug()
    if (competitionSlug) {
      try {
        article.competition = yield call(fetchCompetition, competitionSlug)
        article.competitionExpired = false
      } catch (e) {
        article.competitionExpired = true
      }
      // article.bodyHTML = article.setCompetition(competitionSlug, competition.competition)
    }

    if (article.titleKey !== process.env.RAZZLE_TITLE_KEY) {
      const res = yield call(fetchCanonicalURL, contentKey)
      canonicalUri = res.data.url
    }
    let nextArticles = []
    try {
      nextArticles = yield call(fetchArticlesAPI, canonicalUri.split('/')[1], 0, 9)
    } catch (e) {
      console.log('Unable to fetch nextArticles')
    }
    article.visitorId = yield select(getVisitorId)
    if (!article.visitorId) {
      article.visitorId = fetchVisitorId()
      yield put({ type: USER_SET_VISITORID, payload: article.visitorId })
    }
    yield put({ type: FETCH_ARTICLE_VERIFY_URL, payload: canonicalUri })
    yield put({ type: FETCH_ARTICLE_SUCCESS, payload: article, readNext: nextArticles.contents })
  } catch (e) {
    if (e.response && e.response.status === 404) {
      yield put({ type: FETCH_ARTICLE_NOT_FOUND, payload: e.message })
    } else {
      console.error('ERROR fetchArticleSaga', e)
      yield put({ type: FETCH_ARTICLE_ERROR, payload: e.message })
    }
  }
}

export function * watchFetchArticle () {
  yield takeLatest(FETCH_ARTICLE, fetchArticleSaga)
}

function * setPreviewArticleSaga ({ payload }) {
  try {
    const res = yield call(fetchIonArticle, payload)
    res.relatedArticles = res.relatedArticles ? res.relatedArticles : []

    yield put({ type: FETCH_ARTICLE_SUCCESS, payload: res, readNext: [] })
    // console.log('  Fetching article extras', contentKey, 'took', new Date() - start, 'ms')
  } catch (e) {
    console.log('setPreviewArticleSaga ERROR', e)
    yield put({ type: FETCH_ARTICLE_ERROR, payload: e.message })
  }
}

function * submitCompetitionSaga ({ payload }) {
  const formData = payload
  try {
    const res = yield call(submitCompetitionAPI, formData)
    if (res.success) {
      yield put({ type: SUBMIT_COMPETITION_SUCCESS })
    } else {
      yield put({ type: SUBMIT_COMPETITION_ERROR, payload: res.errors })
    }
    // let article = yield select(getArticle)
    // article.bodyHTML = article.bodyHTML.replace(/(<form([^]*)>)([.]*)(<\/form>)/g, '$1<p id="success-message">You have successfully entered our competition</p>$2')
    // yield put({ type: SUBMIT_COMPETITION_SUCCESS, payload: article })
  } catch (e) {
    console.error('ERROR while submitting to competition', e.message)
    yield put({ type: SUBMIT_COMPETITION_ERROR, payload: { message: e.message } })
  }
}

export function * watchPreviewArticle () {
  yield takeLatest(SET_PREVIEW_ARTICLE, setPreviewArticleSaga)
}

export function * watchSubmitCompetition () {
  yield takeEvery(SUBMIT_COMPETITION, submitCompetitionSaga)
}

// Saga actions
export const getVisitorId = (state) => state.user.visitorId
export const getArticle = (state) => state.article.article
export const fetchArticleContent = (contentKey) => ({ type: FETCH_ARTICLE_CONTENT, isFetching: true, hasFetched: false, contentKey })
export const fetchArticle = (contentKey) => ({ type: FETCH_ARTICLE, isFetching: true, hasFetched: false, contentKey })
export const setPreviewArticle = (data) => ({ type: SET_PREVIEW_ARTICLE, payload: data })
export const submitCompetition = (data) => ({ type: SUBMIT_COMPETITION, payload: data })
export const serverArticleRender = (payload) => ({ type: SERVER_ARTICLE_RENDER, payload })

export const initialState = {
  lastFetch: 0,
  didInvalidate: false,
  isFetching: false,
  hasFetched: false,
  hasError: false,
  is404: false,
  checkCanonical: false,
  canonical: null,
  error: null,
  article: null, // do not mutate these
  previewArticle: false,
  readNext: [],
  readArticles: []
}

export const Reducer = (state = initialState, { type, payload, readNext, meta }) => {
  switch (type) {
    case SET_PREVIEW_ARTICLE:
      return Object.assign({}, state, {
        previewArticle: true
      })
    case FETCH_ARTICLE_VERIFY_URL:
      return Object.assign({}, state, {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        hasError: false,
        checkCanonical: true,
        canonical: payload,
        error: null
      })
    case FETCH_ARTICLE_CONTENT:
      // Do not update the state
      return state
    case FETCH_ARTICLE:
      return Object.assign({}, state, {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        hasError: false,
        is404: false,
        checkCanonical: false,
        canonical: null,
        error: null
      })
    case FETCH_ARTICLE_CONTENT_ERROR:
      // Do not update the state
      return state
    case FETCH_ARTICLE_NOT_FOUND:
      return Object.assign({}, state, {
        hasError: true,
        is404: true,
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        checkCanonical: false,
        canonical: null
      })
    case FETCH_ARTICLE_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        is404: false,
        error: payload,
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        checkCanonical: false,
        canonical: null
      })
    case FETCH_ARTICLE_CONTENT_SUCCESS:
      // Do not update the state
      return state
    case FETCH_ARTICLE_SUCCESS:
      return Object.assign({}, state, {
        lastFetch: new Date(),
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        article: payload,
        readNext: readNext,
        hasError: false,
        is404: false,
        checkCanonical: false,
        // canonical: null,
        error: null
      })
    case SUBMIT_COMPETITION:
      return Object.assign({}, state, {
        isSubmitting: true,
        hasSubmitted: false,
        hasErrorSubmit: false,
        formData: payload
      })
    case SUBMIT_COMPETITION_SUCCESS:
      return Object.assign({}, state, {
        isSubmitting: false,
        hasSubmitted: true,
        hasErrorSubmit: false
      })
    case SUBMIT_COMPETITION_ERROR:
      return Object.assign({}, state, {
        isSubmitting: false,
        hasSubmitted: false,
        hasErrorSubmit: true,
        message: payload
      })
    default:
      return state
  }
}
