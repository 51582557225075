import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'

export const USER_LOGIN = 'api/USER_LOGIN'
export const USER_LOGIN_SUCCESS = 'api/USER_LOGIN_SUCCESS'
export const USER_LOGIN_ERROR = 'api/USER_LOGIN_ERROR'
export const USER_REGISTER = 'api/USER_REGISTER'
export const USER_REGISTER_SUCCESS = 'api/USER_REGISTER_SUCCESS'
export const USER_REGISTER_ERROR = 'api/USER_REGISTER_ERROR'

export const USER_SET_VISITORID = 'api/USER_SET_VISITORID'

const SERVER_URL = process.env.RAZZLE_USER

async function attemptLogin (username, password) {
  return axios.post(SERVER_URL + 'login/' + process.env.RAZZLE_TITLE_KEY, JSON.stringify({ username, password }), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.data)
    .catch(error => {
      console.error('attemptLogin:', error.message)
      throw error
    })
}

async function attemptRegister (name, username, password) {
  console.log('attemptRegister', JSON.stringify({ nickname: name, username, password }), 'to', SERVER_URL + 'register/' + process.env.RAZZLE_TITLE_KEY)
  return axios.post(SERVER_URL + 'register/' + process.env.RAZZLE_TITLE_KEY, JSON.stringify({ nickname: name, username, password }), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.data)
    .catch(error => {
      throw error
    })
}

export function * watchUserLogin () {
  yield takeLatest(USER_LOGIN, userLoginSaga)
}

function * userLoginSaga ({ username, password }) {
  try {
    const token = yield call(attemptLogin, username, password)
    if (window.localStorage) {
      window.localStorage.setItem('token', token)
    }
    yield put({ type: USER_LOGIN_SUCCESS, payload: token })
  } catch (e) {
    if (e.response && e.response.status === 404) {
      yield put({ type: USER_LOGIN_ERROR, payload: e.message })
    } else {
      console.error('ERROR userLoginSaga', e)
      yield put({ type: USER_LOGIN_ERROR, payload: e.response.data })
    }
  }
}

export function * watchUserRegister () {
  yield takeLatest(USER_REGISTER, userRegisterSaga)
}

function * userRegisterSaga ({ name, username, password }) {
  try {
    yield call(attemptRegister, name, username, password)
    yield put({ type: USER_REGISTER_SUCCESS })
  } catch (e) {
    if (e.response && e.response.status === 404) {
      yield put({ type: USER_REGISTER_ERROR, payload: e.message })
    } else {
      console.error('ERROR userRegisterSaga', e)
      yield put({ type: USER_REGISTER_ERROR, payload: e.response.data })
    }
  }
}

// Saga actions
export const userLogin = (username, password) => ({ type: USER_LOGIN, username, password })
export const userRegister = (name, username, password) => ({ type: USER_REGISTER, name, username, password })

export const initialState = {
  userToken: false
}

export const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        userToken: payload
      })
    case USER_REGISTER_SUCCESS:
      return Object.assign({}, state, {
        registerSuccess: true
      })
    case USER_REGISTER_ERROR:
      return Object.assign({}, state, {
        registerError: payload
      })
    case USER_SET_VISITORID:
      console.log('USER_SET_VISITORID', payload)
      return Object.assign({}, state, {
        visitorId: payload
      })
    default:
      return state
  }
}
