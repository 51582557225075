"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _ionImage = require("ion-image");

var _ionUtil = require("ion-util");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ArticleFeaturedImage = function ArticleFeaturedImage(_ref) {
  var article = _ref.article,
      imageWidth = _ref.imageWidth,
      metaImageObjectUrl = _ref.metaImageObjectUrl,
      noImage = _ref.noImage;

  if (!(0, _ionUtil.isEmpty)(article.videos) && !(0, _ionUtil.isEmpty)(article.youtubes)) {
    return _react["default"].createElement("div", {
      className: "article-carousel"
    }, article.videos.map(function (item, index) {
      return _react["default"].createElement("div", {
        key: index
      }, _react["default"].createElement("div", {
        dangerouslySetInnerHTML: {
          __html: item.script
        }
      }));
    }), article.youtubes.map(function (youtube, index) {
      return _react["default"].createElement("a", {
        href: 'https://www.youtube.com/embed/' + youtube.id,
        "data-video": "true",
        "data-caption": youtube.caption,
        "data-img": youtube.url,
        key: youtube.id
      }, _react["default"].createElement("img", {
        alt: youtube.caption,
        src: youtube.url
      }));
    }));
  } else if (!(0, _ionUtil.isEmpty)(article.videos)) {
    var video = article.videos[0];

    if (video && video.url && video.script && /\.mp4$/.test(video.url)) {
      return _react["default"].createElement("div", null, _react["default"].createElement("div", {
        id: "featuredVideoContainer",
        dangerouslySetInnerHTML: {
          __html: video.script
        }
      }));
    } else {
      console.warn('ArticleFeaturedImage tried to render an unsupported Video format', article.contentKey, article.videos);
    }
  }

  if (article.images) {
    return _react["default"].createElement(_ionImage.ImageOrNoImage, {
      key: article.contentKey,
      image: article.images[0],
      width: imageWidth,
      alt: article.headline,
      shape: "16x9",
      noImage: noImage
    });
  } else {
    return false;
  }
};

var _default = ArticleFeaturedImage;
exports["default"] = _default;