import axios from 'axios'
import { call, put, takeEvery } from 'redux-saga/effects'

export const SUBSCRIBE = 'api/SUBSCRIBE'
export const SUBSCRIBE_SUCCESS = 'api/SUBSCRIBE_SUCCESS'
export const SUBSCRIBE_ERROR = 'api/SUBSCRIBE_ERROR'

export const UPDATE_DATA = 'api/UPDATE_DATA'

const SERVER_URL = typeof window !== 'undefined' ? '' : 'http://localhost:' + process.env.PORT

const subscribeAPI = (data) => {
  return axios.post(SERVER_URL + '/data/newsletter/subscribe', data)
    .then(response => response.data)
    .catch(err => {
      // console.error(err)
      throw err
    })
}

function * subscribeSaga ({ data }) {
  try {
    yield call(subscribeAPI, data)
    yield put({ type: SUBSCRIBE_SUCCESS })
  } catch (e) {
    console.error('ERROR while subscribing', e.message)
    yield put({ type: SUBSCRIBE_ERROR, payload: { message: e.message } })
  }
}

export function * watchSubscribe () {
  yield takeEvery(SUBSCRIBE, subscribeSaga)
}

// Saga actions
export const subscribeNewsletter = (data) => ({ type: SUBSCRIBE, data })
export const updateForm = (data) => ({ type: UPDATE_DATA, payload: data })

const defaultState = {
  contact_name: '',
  contact_lastname: '',
  email: '',
  contact_country: 'za',
  lists: [17381],
  errorMessage: '',
  captchaVerified: false
}

export const Reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_DATA:
      return Object.assign({}, state, payload)
    case SUBSCRIBE:
      return Object.assign({}, state, {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        hasError: false,
        error: null,
        data: payload
      })
    case SUBSCRIBE_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        error: payload.message,
        hasFetched: true,
        isFetching: false,
        didInvalidate: true
      })
    case SUBSCRIBE_SUCCESS:
      return Object.assign({}, state, {
        hasFetched: true,
        isFetching: false,
        didInvalidate: false
      })
    default:
      return state
  }
}
