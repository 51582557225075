import axios from 'axios'
import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'

export const SEARCH_ARTICLES = 'api/SEARCH_ARTICLES'
export const SEARCH_ARTICLES_SUCCESS = 'api/SEARCH_ARTICLES_SUCCESS'
export const SEARCH_ARTICLES_ERROR = 'api/SEARCH_ARTICLES_ERROR'
export const SEARCH_ARTICLES_EMPTY = 'api/SEARCH_ARTICLES_EMPTY'
export const SEARCH_ARTICLES_MORE = 'api/SEARCH_ARTICLES_MORE'
export const SEARCH_ARTICLES_MORE_SUCCESS = 'api/SEARCH_ARTICLES_MORE_SUCCESS'
export const SEARCH_ARTICLES_MORE_ERROR = 'api/SEARCH_ARTICLES_MORE_ERROR'

const SERVER_URL = typeof window !== 'undefined' ? '/data/content' : process.env.RAZZLE_CONTENT

const searchArticlesAPI = (index, pageSize, query) => {
  return axios.get([SERVER_URL + '/v2/section', process.env.RAZZLE_TITLE_KEY, index, pageSize + 1, 'search?q=' + query].join('/'))
    .then(response => {
      return response.data
    })
    .catch(err => {
      // console.error(err)
      throw err
    })
}

function * searchArticlesSaga ({ payload }) {
  const { index, pageSize, query } = payload
  try {
    const res = yield call(searchArticlesAPI, index, pageSize, query)
    const _payload = {
      lastFetch: new Date(),
      query,
      hasMore: res.contents.length > pageSize,
      articles: res.contents.slice(0, pageSize)
    }
    if (Array.isArray(res.contents)) {
      yield put({ type: SEARCH_ARTICLES_SUCCESS, payload: _payload })
    } else {
      yield put({ type: SEARCH_ARTICLES_EMPTY, payload: _payload })
    }
  } catch (e) {
    console.error('ERROR while searching section', query, 'json:', e.message)
    yield put({ type: SEARCH_ARTICLES_ERROR, payload: { query, message: e.message } })
  }
}

function * searchArticlesMoreSaga ({ payload }) {
  const { index, pageSize, query } = payload
  const currentArticles = yield select(getArticles)
  try {
    const res = yield call(searchArticlesAPI, index, pageSize, query)
    const data = {
      query,
      lastFetch: new Date(),
      hasMore: res.contents.length > pageSize,
      articles: currentArticles.concat(res.contents.slice(0, pageSize))
    }
    yield put({ type: SEARCH_ARTICLES_MORE_SUCCESS, payload: data })
  } catch (e) {
    console.error('ERROR while fetching search result', query, 'json:', e.message)
    yield put({ type: SEARCH_ARTICLES_MORE_ERROR, payload: { query, message: e.message } })
  }
}

export function * watchSearchArticles () {
  yield takeEvery(SEARCH_ARTICLES, searchArticlesSaga)
}

export function * watchSearchArticlesMore () {
  yield takeLatest(SEARCH_ARTICLES_MORE, searchArticlesMoreSaga)
}

// Saga actions
const getArticles = (state) => state.search.articles
export const searchArticles = (query, index, pageSize) => ({ type: SEARCH_ARTICLES, payload: { query, index, pageSize } })
export const searchArticlesMore = (query, index, pageSize) => ({ type: SEARCH_ARTICLES_MORE, payload: { query, index, pageSize } })
const defaultState = {
  didInvalidate: false,
  isFetching: false,
  hasFetched: false,
  hasError: false,
  query: '',
  error: null,
  isEmpty: false
}

export const Reducer = (state = defaultState, { type, payload, meta, message }) => {
  switch (type) {
    case SEARCH_ARTICLES_MORE:
      return Object.assign({}, state, {
        didInvalidate: false,
        isLoadingMore: true,
        hasFetched: true,
        hasError: false,
        isEmpty: false
      })
    case SEARCH_ARTICLES:
      return Object.assign({}, state, {
        didInvalidate: false,
        isFetching: true,
        isLoadingMore: false,
        hasFetched: false,
        hasError: false,
        query: payload.query,
        error: null,
        isEmpty: false
      })
    case SEARCH_ARTICLES_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        error: payload.message,
        hasFetched: false,
        isFetching: false,
        isLoadingMore: false,
        didInvalidate: true,
        query: payload.query,
        isEmpty: false
      })
    case SEARCH_ARTICLES_EMPTY:
      return Object.assign({}, state, {
        hasError: false,
        hasFetched: true,
        isFetching: false,
        isLoadingMore: false,
        didInvalidate: true,
        query: payload.query,
        isEmpty: true
      })
    case SEARCH_ARTICLES_SUCCESS:
      return Object.assign({}, state, {
        hasFetched: true,
        isFetching: false,
        isLoadingMore: false,
        didInvalidate: false,
        isEmpty: false,
        query: payload.query,
        articles: payload.articles,
        hasMore: payload.hasMore,
        lastFetch: payload.lastFetch
      })
    case SEARCH_ARTICLES_MORE_SUCCESS:
      return Object.assign({}, state, {
        hasFetched: true,
        isLoadingMore: false,
        didInvalidate: false,
        isEmpty: false,
        query: payload.query,
        articles: payload.articles,
        hasMore: payload.hasMore,
        lastFetch: payload.lastFetch
      })
    case SEARCH_ARTICLES_MORE_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        error: payload.message,
        hasFetched: true,
        isLoadingMore: false,
        didInvalidate: true,
        query: payload.query,
        isEmpty: false
      })
    default:
      return state
  }
}
