import App from './app/components/App'
import { BrowserRouter } from 'react-router-dom'
import React from 'react'
import { loadableReady } from '@loadable/component'
import { hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import createStore from './store/createStore'
import rootSaga from './store/sagas'
import { updateConnectivity } from './store/app'

const store = createStore(window.__PRELOADED_STATE__)

store.runSaga(rootSaga)

window.addEventListener('offline', () => {
  store.dispatch(updateConnectivity(false))
})

window.addEventListener('online', () => {
  store.dispatch(updateConnectivity(true))
})

loadableReady(() => {
  hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  )
})

if (module.hot) {
  module.hot.accept()
}
