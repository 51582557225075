import axios from 'axios'
import { call, put, takeEvery } from 'redux-saga/effects'

export const SUBSCRIBEPRIINT = 'api/SUBSCRIBEPRIINT'
export const SUBSCRIBEPRIINT_SUCCESS = 'api/SUBSCRIBEPRIINT_SUCCESS'
export const SUBSCRIBEPRIINT_ERROR = 'api/SUBSCRIBEPRIINT_ERROR'
export const REINITIALIZE = 'api/REINITIALIZE'

export const UPDATE_DATA = 'api/UPDATE_DATA'

const SERVER_URL = typeof window !== 'undefined' ? '' : 'http://localhost:' + process.env.PORT

const subscribeAPI = (data) => {
  return axios.post(SERVER_URL + '/data/subscribe/print', data)
    .then(response => response.data)
    .catch(err => {
      // console.error(err)
      throw err
    })
}

function * subscribeSaga ({ data }) {
  try {
    yield call(subscribeAPI, data)
    yield put({ type: SUBSCRIBEPRIINT_SUCCESS })
  } catch (e) {
    console.error('ERROR while subscribing', e.message)
    yield put({ type: SUBSCRIBEPRIINT_ERROR, payload: { message: e.message } })
  }
}

export function * watchSubscribePrint () {
  yield takeEvery(SUBSCRIBEPRIINT, subscribeSaga)
}

// Saga actions
export const subscribePrint = (data) => ({ type: SUBSCRIBEPRIINT, data })
export const updateForm = (data) => ({ type: UPDATE_DATA, payload: data })
export const reinitialize = () => ({ type: REINITIALIZE, payload: {} })

const defaultState = {
  firstName: '',
  surname: '',
  email: '',
  phone: '',
  address: '',
  address_2: '',
  city: '',
  province: '',
  postal: '',
  country: 'za'
}

export const Reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_DATA:
      return Object.assign({}, state, payload)
    case REINITIALIZE:
      return Object.assign({}, defaultState)
    case SUBSCRIBEPRIINT:
      return Object.assign({}, state, {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        hasError: false,
        error: null,
        data: payload
      })
    case SUBSCRIBEPRIINT_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        error: payload.message,
        hasFetched: true,
        isFetching: false,
        didInvalidate: true
      })
    case SUBSCRIBEPRIINT_SUCCESS:
      return Object.assign({}, state, {
        hasFetched: true,
        isFetching: false,
        didInvalidate: false
      })
    default:
      return state
  }
}
