import { compose, createStore, applyMiddleware } from 'redux'
import createSagaMiddleware, { END } from 'redux-saga'
import rootReducer from './rootReducer'
import { isConnected } from './app'

// Import createMiddleware and a target
import { createMiddleware as createBeaconMiddleware } from 'redux-beacon'
import GoogleTagManager from '@redux-beacon/google-tag-manager'
import OfflineWeb from '@redux-beacon/offline-web'
import IONAnalytics from 'redux-beacon-IONAnalytics'

import { eventsMap } from './events'
/* To enable Saga Monitoring, uncomment the following */
// import createSagaMonitor from './sagaMonitor'
const gtm = GoogleTagManager()
const offlineStorage = OfflineWeb(isConnected)

function beaconMiddleware (isServer) {
  if (!isServer) {
    return [
      createBeaconMiddleware(eventsMap, gtm, { offlineStorage }),
      createBeaconMiddleware(eventsMap, IONAnalytics, { offlineStorage })
    ]
  } else {
    return [
      createBeaconMiddleware(eventsMap, IONAnalytics)
    ]
  }
}

/* To enable Saga Monitoring, uncomment the following
// configuration
const config = {
  level: 'log',
  effectTrigger: true,
  effectResolve: true,
  actionDispatch: true
}
*/
// we pass fetch so that we can use global on window, node-fetch on server
export const sagaMiddleware = createSagaMiddleware({
  // To enable Saga Monitoring, uncomment the following
  // sagaMonitor: createSagaMonitor(config)
})

export default (initialState) => {
  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
  const enhancer = composeEnhancers(
    applyMiddleware(
      ...beaconMiddleware(typeof window === 'undefined'),
      sagaMiddleware
    )
  )

  const store = createStore(rootReducer, initialState, enhancer)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./rootReducer', () => {
      const nextRootReducer = require('./rootReducer').default
      store.replaceReducer(nextRootReducer)
    })
  }

  store.runSaga = sagaMiddleware.run

  store.close = () => store.dispatch(END)

  return store
}
